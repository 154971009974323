export const ACLAccessKey = {
   createdOn: 'Date',
   includesChildren: 'Boolean',
   keycode: 'KeyCode',
   mask: 'Number',
   modifiedOn: 'Date',
   role: 'ACLRole',
};

export const ACLRole = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   name: 'String',
   accessKeys: 'ACLAccessKey',
};

export const AbstractInvoice = {
   allowAutoPay: 'Boolean',
   amountOwing: 'Number',
   billToAddress: 'String',
   confirmationStatus: 'ConfirmationStatus',
   createdOn: 'Date',
   customerReference: 'String',
   dateDue: 'Date',
   description: 'String',
   id: 'Number',
   invoiceDate: 'Date',
   invoiceNumber: 'Number',
   quoteNumber: 'Number',
   modifiedOn: 'Date',
   overdue: 'Number',
   publicNotes: 'String',
   shippingAddress: 'String',
   source: 'PaymentSource',
   type: 'InvoiceType',
   title: 'String',
   paymentInLines: 'PaymentInLine',
   paymentOutLines: 'PaymentOutLine',
   invoiceLines: 'AbstractInvoiceLine',
   noteRelations: 'NoteRelation',
   attachmentRelations: 'AttachmentRelation',
   authorisedRebillingCard: 'PaymentIn',
   corporatePassUsed: 'CorporatePass',
   createdByUser: 'SystemUser',
   debtorsAccount: 'Account',
   invoiceDueDates: 'InvoiceDueDate',
   lead: 'Lead',
   taggingRelations: 'TagRelation',
   abstractInvoiceLines: 'AbstractInvoiceLine',
   contact: 'Contact',
   accountTransactions: 'AccountTransaction',
   courseClasses: 'CourseClass',
   banking: 'Banking',
   paymentsOut: 'PaymentOut',
   paymentsIn: 'PaymentIn',
   classes: 'CourseClass',
   discounts: 'Discount',
   enrolments: 'Enrolment',
};

export const AbstractInvoiceLine = {
   createdOn: 'Date',
   description: 'String',
   discountEachExTax: 'Number',
   id: 'Number',
   modifiedOn: 'Date',
   prepaidFeesRemaining: 'Number',
   priceEachExTax: 'Number',
   quantity: 'Number',
   sortOrder: 'Number',
   taxEach: 'Number',
   title: 'String',
   unit: 'String',
   account: 'Account',
   cosAccount: 'Account',
   prepaidFeesAccount: 'Account',
   tax: 'Tax',
   enrolment: 'Enrolment',
   abstractInvoice: 'AbstractInvoice',
};

export const Account = {
   accountCode: 'String',
   createdOn: 'Date',
   description: 'String',
   id: 'Number',
   isEnabled: 'Boolean',
   modifiedOn: 'Date',
   type: 'AccountType',
   bankings: 'Banking',
   courseClasses: 'CourseClass',
   discounts: 'Discount',
   invoiceLines: 'AbstractInvoiceLine',
   quoteLines: 'AbstractInvoiceLine',
   invoices: 'AbstractInvoice',
   payableTaxes: 'Tax',
   paymentInLines: 'PaymentInLine',
   paymentMethods: 'PaymentMethod',
   paymentOutLines: 'PaymentOutLine',
   payments: 'PaymentIn',
   paymentsOut: 'PaymentOut',
   prepaidInvoiceLines: 'AbstractInvoiceLine',
   products: 'Product',
   receivableTaxes: 'Tax',
   tax: 'Tax',
   transactions: 'AccountTransaction',
   vouchers: 'Product',
};

export const AccountTransaction = {
   amount: 'Number',
   createdOn: 'Date',
   description: 'String',
   foreignRecordId: 'Number',
   id: 'Number',
   modifiedOn: 'Date',
   tableName: 'AccountTransactionType',
   transactionDate: 'Date',
   account: 'Account',
   banking: 'Banking',
   invoice: 'Invoice',
   paymentIn: 'PaymentIn',
   paymentOut: 'PaymentOut',
   contact: 'Contact',
};

export const Application = {
   confirmationStatus: 'ConfirmationStatus',
   createdOn: 'Date',
   enrolBy: 'Date',
   feeOverride: 'Number',
   id: 'Number',
   modifiedOn: 'Date',
   reason: 'String',
   source: 'PaymentSource',
   status: 'ApplicationStatus',
   attachmentRelations: 'AttachmentRelation',
   course: 'Course',
   createdByUser: 'SystemUser',
   customFields: 'CustomField',
   noteRelations: 'NoteRelation',
   student: 'Student',
   taggingRelations: 'TagRelation',
   tags: 'Tag',
};

export const Article = {
   confirmationStatus: 'ConfirmationStatus',
   createdOn: 'Date',
   expiryDate: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   status: 'ProductStatus',
   code: 'String',
   valueOnPurchase: 'Number',
   redemptionValue: 'Number',
   noteRelations: 'NoteRelation',
   taggingRelations: 'TagRelation',
   invoiceLine: 'AbstractInvoiceLine',
   product: 'Product',
   redeemedInvoice: 'Invoice',
   redeemedPaymentIn: 'PaymentIn',
   redeemableBy: 'Contact',
   purchasedBy: 'Contact',
   redeemedEnrolment: 'Enrolment',
   type: 'ProductType',
};

export const ArticleProduct = {
   weight: 'Number',
   createdOn: 'Date',
   description: 'String',
   expiryDays: 'Number',
   expiryType: 'ExpiryType',
   id: 'Number',
   isOnSale: 'Boolean',
   isWebVisible: 'Boolean',
   modifiedOn: 'Date',
   name: 'String',
   notes: 'String',
   priceExTax: 'Number',
   sku: 'String',
   taxAdjustment: 'Number',
   checkoutRelations: 'CheckoutContactRelation',
   corporatePassProducts: 'CorporatePassProduct',
   incomeAccount: 'Account',
   fieldConfigurationScheme: 'FieldConfigurationScheme',
   leadItems: 'LeadItem',
   productItems: 'ProductItem',
   tax: 'Tax',
   type: 'ProductType',
   allRelatedCourses: 'Course',
   allRelatedProducts: 'Product',
};

export const Assessment = {
   active: 'Boolean',
   code: 'String',
   createdOn: 'Date',
   description: 'String',
   id: 'Number',
   modifiedOn: 'Date',
   name: 'String',
   assessmentClasses: 'AssessmentClass',
   attachmentRelations: 'AttachmentRelation',
   noteRelations: 'NoteRelation',
   taggingRelations: 'TagRelation',
   gradingType: 'GradingType',
   tags: 'Tag',
};

export const AssessmentClass = {
   createdOn: 'Date',
   dueDate: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   releaseDate: 'Date',
   assessment: 'Assessment',
   assessmentClassModules: 'AssessmentClassModule',
   assessmentClassTutors: 'AssessmentClassTutor',
   assessmentSubmissions: 'AssessmentSubmission',
   courseClass: 'CourseClass',
   class: 'CourseClass',
};

export const AssessmentClassModule = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   assessmentClass: 'AssessmentClass',
   module: 'Module',
};

export const AssessmentClassTutor = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   assessmentClass: 'AssessmentClass',
   tutor: 'Tutor',
};

export const AssessmentSubmission = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   submittedOn: 'Date',
   markedOn: 'Date',
   grade: 'Number',
   assessmentClass: 'AssessmentClass',
   attachmentRelations: 'AttachmentRelation',
   enrolment: 'Enrolment',
   noteRelations: 'NoteRelation',
   markedBy: 'Contact',
};

export const AttachmentRelation = {
   createdOn: 'Date',
   entityIdentifier: 'String',
   entityRecordId: 'Number',
   id: 'Number',
   modifiedOn: 'Date',
   specialType: 'AttachmentSpecialType',
   document: 'Document',
};

export const Attendance = {
   attendanceType: 'AttendanceType',
   attendedFrom: 'Date',
   attendedUntil: 'Date',
   createdOn: 'Date',
   durationMinutes: 'Number',
   id: 'Number',
   markedByTutorDate: 'Date',
   modifiedOn: 'Date',
   note: 'String',
   markedByTutor: 'Tutor',
   session: 'Session',
   student: 'Student',
};

export const Audit = {
   action: 'AuditAction',
   created: 'Date',
   entityId: 'Number',
   entityIdentifier: 'String',
   id: 'Number',
   message: 'String',
   systemUser: 'SystemUser',
};

export const AutomationBinding = {
   createdOn: 'Date',
   dataType: 'DataType',
   entityIdentifier: 'String',
   id: 'Number',
   lable: 'String',
   modifiedOn: 'Date',
   name: 'String',
   value: 'String',
};

export const Banking = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   settlementDate: 'Date',
   type: 'BankingType',
   adminSite: 'Site',
   assetAccount: 'Account',
   createdBy: 'SystemUser',
   paymentsIn: 'PaymentIn',
   paymentsOut: 'PaymentOut',
};

export const Certificate = {
   awardedOn: 'Date',
   certificateNumber: 'Number',
   createdOn: 'Date',
   expiryDate: 'Date',
   id: 'Number',
   isQualification: 'Boolean',
   issuedOn: 'Date',
   modifiedOn: 'Date',
   printedOn: 'Date',
   privateNotes: 'String',
   publicNotes: 'String',
   revokedOn: 'Date',
   uniqueCode: 'String',
   attachmentRelations: 'AttachmentRelation',
   certificateOutcomes: 'CertificateOutcome',
   qualification: 'Qualification',
   student: 'Student',
};

export const CertificateOutcome = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   certificate: 'Certificate',
   outcome: 'Outcome',
};

export const Checkout = {
   id: 'Number',
   createdOn: 'Date',
   modifiedOn: 'Date',
   checkoutUrl: 'String',
   totalValue: 'Number',
   payer: 'Contact',
   products: 'CheckoutContactRelation',
   contactRelations: 'CheckoutContactRelation',
   applications: 'CheckoutContactRelation',
   waitingCourses: 'CheckoutContactRelation',
   classes: 'CheckoutContactRelation',
};

export const CheckoutContactRelation = {
   id: 'Number',
   relatedEntityName: 'String',
   selected: 'Boolean',
   createdOn: 'Date',
   modifiedOn: 'Date',
   contact: 'Contact',
   checkout: 'Checkout',
};

export const ClassCost = {
   createdOn: 'Date',
   description: 'String',
   flowType: 'ClassCostFlowType',
   id: 'Number',
   invoiceToStudent: 'Boolean',
   isSunk: 'Boolean',
   maximumCost: 'Number',
   minimumCost: 'Number',
   modifiedOn: 'Date',
   onCostRate: 'Number',
   payableOnEnrolment: 'Boolean',
   perUnitAmountExTax: 'Number',
   repetitionType: 'ClassCostRepetitionType',
   taxAdjustment: 'Number',
   unitCount: 'Number',
   contact: 'Contact',
   courseClass: 'CourseClass',
   createdBy: 'SystemUser',
   discountCourseClass: 'DiscountCourseClass',
   paylines: 'PayLine',
   tax: 'Tax',
   tutorRole: 'CourseClassTutor',
   class: 'CourseClass',
};

export const ConcessionType = {
   createdOn: 'Date',
   hasConcessionNumber: 'Boolean',
   hasExpiryDate: 'Boolean',
   id: 'Number',
   isEnabled: 'Boolean',
   modifiedOn: 'Date',
   name: 'String',
   concessionTypeDiscounts: 'DiscountConcessionType',
   createdBy: 'SystemUser',
   studentConcessions: 'StudentConcession',
};

export const Contact = {
   abn: 'String',
   allowEmail: 'Boolean',
   allowPost: 'Boolean',
   allowSms: 'Boolean',
   birthDate: 'Date',
   createdOn: 'Date',
   deliveryStatusEmail: 'Number',
   deliveryStatusPost: 'Number',
   deliveryStatusSms: 'Number',
   email: 'String',
   fax: 'String',
   firstName: 'String',
   gender: 'Gender',
   homePhone: 'String',
   honorific: 'String',
   id: 'Number',
   invoiceTerms: 'Number',
   isCompany: 'Boolean',
   isStudent: 'Boolean',
   isTutor: 'Boolean',
   lastName: 'String',
   message: 'String',
   middleName: 'String',
   mobilePhone: 'String',
   modifiedOn: 'Date',
   postcode: 'String',
   state: 'String',
   street: 'String',
   suburb: 'String',
   tfn: 'String',
   title: 'String',
   uniqueCode: 'String',
   workPhone: 'String',
   attachmentRelations: 'AttachmentRelation',
   abandonedCarts: 'Checkout',
   classCosts: 'ClassCost',
   concessionsAuthorised: 'StudentConcession',
   contactDuplicate: 'ContactDuplicate',
   corporatePasses: 'CorporatePass',
   country: 'Country',
   customFields: 'CustomField',
   fromContacts: 'ContactRelation',
   invoices: 'AbstractInvoice',
   quotes: 'AbstractInvoice',
   leads: 'Lead',
   messages: 'Message',
   noteRelations: 'NoteRelation',
   paymentsIn: 'PaymentIn',
   paymentsOut: 'PaymentOut',
   payslips: 'Payslip',
   productItems: 'ProductItem',
   student: 'Student',
   taggingRelations: 'TagRelation',
   taxOverride: 'Tax',
   toContacts: 'ContactRelation',
   tutor: 'Tutor',
   unavailableRuleRelations: 'UnavailableRuleRelation',
   accountTransactions: 'AccountTransaction',
   tutorCourseClass: 'CourseClass',
   studentCourseClass: 'CourseClass',
   studentEnrolments: 'Enrolment',
   allRelatedContacts: 'Contact',
   fromRelatedContacts: 'Contact',
   toRelatedContacts: 'Contact',
   fromRelationType: 'ContactRelationType',
   toRelationType: 'ContactRelationType',
   allRelatedContactsToCourse: 'Course',
   allRelatedContactsToAssessment: 'Assessment',
   tags: 'Tag',
};

export const ContactDuplicate = {
   contactToDeleteId: 'Number',
   contactToDeleteWillowId: 'Number',
   createdOn: 'Date',
   description: 'String',
   id: 'Number',
   modifiedOn: 'Date',
   status: 'ContactDuplicateStatus',
   contactToUpdate: 'Contact',
   createdByUser: 'SystemUser',
};

export const ContactRelation = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   fromContact: 'Contact',
   relationType: 'ContactRelationType',
   toContact: 'Contact',
};

export const ContactRelationType = {
   createdOn: 'Date',
   delegatedAccessToContact: 'Boolean',
   fromContactName: 'String',
   id: 'Number',
   modifiedOn: 'Date',
   toContactName: 'String',
   contactRelations: 'ContactRelation',
   createdBy: 'SystemUser',
   membeshipDiscountRelations: 'DiscountMembershipRelationType',
};

export const CorporatePass = {
   createdOn: 'Date',
   expiryDate: 'Date',
   id: 'Number',
   invoiceEmail: 'String',
   modifiedOn: 'Date',
   password: 'String',
   contact: 'Contact',
   corporatePassCourseClasses: 'CorporatePassCourseClass',
   corporatePassDiscounts: 'CorporatePassDiscount',
   corporatePassProduct: 'CorporatePassProduct',
   invoices: 'AbstractInvoice',
};

export const CorporatePassCourseClass = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   corporatePass: 'CorporatePass',
   courseClass: 'CourseClass',
};

export const CorporatePassDiscount = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   corporatePass: 'CorporatePass',
   discount: 'Discount',
};

export const CorporatePassProduct = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   corporatePass: 'CorporatePass',
   product: 'Product',
};

export const Country = {
   createdOn: 'Date',
   id: 'Number',
   isoCodeAlpha2: 'String',
   isoCodeAlpha3: 'String',
   isoCodeNumeric: 'Number',
   modifiedOn: 'Date',
   name: 'String',
   saccCode: 'Number',
   contacts: 'Contact',
   residents: 'Student',
   sites: 'Site',
   studentsBorn: 'Student',
};

export const Course = {
   allowWaitingLists: 'Boolean',
   attainmentText: 'String',
   code: 'String',
   createdOn: 'Date',
   currentlyOffered: 'Boolean',
   enrolmentType: 'CourseEnrolmentType',
   fieldOfEducation: 'String',
   id: 'Number',
   isShownOnWeb: 'Boolean',
   isSufficientForQualification: 'Boolean',
   isTraineeship: 'Boolean',
   isVET: 'Boolean',
   modifiedOn: 'Date',
   name: 'String',
   printedBrochureDescription: 'String',
   reportableHours: 'Number',
   webDescription: 'RichText',
   shortWebDescription: 'String',
   feeHelpClass: 'Boolean',
   fullTimeLoad: 'String',
   checkoutRelations: 'CheckoutContactRelation',
   applications: 'Application',
   attachmentRelations: 'AttachmentRelation',
   courseClasses: 'CourseClass',
   courseModules: 'CourseModule',
   customFields: 'CustomField',
   fieldConfigurationSchema: 'FieldConfigurationScheme',
   leadItems: 'LeadItem',
   noteRelations: 'NoteRelation',
   qualification: 'Qualification',
   taggingRelations: 'TagRelation',
   unavailableRuleRelations: 'UnavailableRuleRelation',
   voucherProductCourses: 'VoucherProductCourse',
   waitingLists: 'WaitingList',
   faculty: 'Faculty',
   allRelatedCourses: 'Course',
   allRelatedProducts: 'Product',
   classes: 'CourseClass',
   isActive: 'Boolean',
   tags: 'Tag',
};

export const CourseClass = {
   attendanceType: 'CourseClassAttendanceType',
   budgetedPlaces: 'Number',
   censusDate: 'Date',
   code: 'String',
   createdOn: 'Date',
   deliveryMode: 'DeliveryMode',
   deposit: 'Number',
   detBookingId: 'String',
   endDateTime: 'Date',
   expectedHours: 'Number',
   feeExGst: 'Number',
   finalDETexport: 'String',
   fundingSource: 'ClassFundingSource',
   id: 'Number',
   initialDETexport: 'String',
   isActive: 'Boolean',
   isCancelled: 'Boolean',
   isClassFeeApplicationOnly: 'Boolean',
   type: 'CourseClassType',
   isShownOnWeb: 'Boolean',
   materials: 'String',
   maxStudentAge: 'Number',
   maximumDays: 'Number',
   maximumPlaces: 'Number',
   message: 'String',
   midwayDETexport: 'String',
   minStudentAge: 'Number',
   minimumPlaces: 'Number',
   modifiedOn: 'Date',
   reportableHours: 'Number',
   startDateTime: 'Date',
   suppressAvetmissExport: 'Boolean',
   taxAdjustment: 'Number',
   vetCourseSiteID: 'Number',
   vetFundingSourceStateID: 'String',
   vetPurchasingContractID: 'String',
   vetPurchasingContractScheduleID: 'String',
   webDescription: 'RichText',
   minimumSessionsToComplete: 'Number',
   checkoutApplicationRelations: 'CheckoutContactRelation',
   checkoutCourseClassRelations: 'CheckoutContactRelation',
   assessmentClasses: 'AssessmentClass',
   attachmentRelations: 'AttachmentRelation',
   corporatePassCourseClass: 'CorporatePassCourseClass',
   costs: 'ClassCost',
   course: 'Course',
   discountCourseClasses: 'DiscountCourseClass',
   enrolments: 'Enrolment',
   incomeAccount: 'Account',
   quoteLines: 'AbstractInvoiceLine',
   invoiceLines: 'AbstractInvoiceLine',
   noteRelations: 'NoteRelation',
   paymentPlanLines: 'CourseClassPaymentPlanLine',
   relatedFundingSource: 'FundingSource',
   room: 'Room',
   sessions: 'Session',
   taggingRelations: 'TagRelation',
   tax: 'Tax',
   tutorRoles: 'CourseClassTutor',
   customFields: 'CustomField',
   enrolmentCount: 'Number',
   isMaxEnrolments: 'Boolean',
   isMinEnrolments: 'Boolean',
   sessionsCount: 'Number',
   isDistantLearningCourse: 'Boolean',
};

export const CourseClassPaymentPlanLine = {
   amount: 'Number',
   createdOn: 'Date',
   dayOffset: 'Number',
   id: 'Number',
   modifiedOn: 'Date',
   courseClass: 'CourseClass',
};

export const CourseClassTutor = {
   confirmedOn: 'Date',
   createdOn: 'Date',
   id: 'Number',
   inPublicity: 'Boolean',
   modifiedOn: 'Date',
   classCosts: 'ClassCost',
   courseClass: 'CourseClass',
   definedTutorRole: 'DefinedTutorRole',
   sessionsTutors: 'TutorAttendance',
   tutor: 'Tutor',
};

export const CourseModule = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   course: 'Course',
   module: 'Module',
};

export const CustomField = {
   createdOn: 'Date',
   entityIdentifier: 'String',
   id: 'Number',
   modifiedOn: 'Date',
   value: 'String',
   customFieldType: 'CustomFieldType',
};

export const CustomFieldType = {
   createdOn: 'Date',
   dataType: 'DataType',
   defaultValue: 'String',
   pattern: 'String',
   entityIdentifier: 'String',
   id: 'Number',
   isMandatory: 'Boolean',
   key: 'String',
   modifiedOn: 'Date',
   name: 'String',
   sortOrder: 'Number',
   customFields: 'CustomField',
};

export const DefinedTutorRole = {
   active: 'Boolean',
   description: 'String',
   id: 'Number',
   name: 'String',
   payRates: 'PayRate',
   tutorRoles: 'CourseClassTutor',
};

export const Discount = {
   addByDefault: 'Boolean',
   code: 'String',
   createdOn: 'Date',
   discountDollar: 'Number',
   discountMax: 'Number',
   discountMin: 'Number',
   discountPercent: 'Number',
   discountType: 'DiscountType',
   hideOnWeb: 'Boolean',
   id: 'Number',
   availableFor: 'DiscountAvailabilityType',
   limitPreviousEnrolment: 'Boolean',
   minEnrolments: 'Number',
   minValue: 'Number',
   modifiedOn: 'Date',
   name: 'String',
   predictedStudentsPercentage: 'Number',
   publicDescription: 'String',
   rounding: 'MoneyRounding',
   studentAge: 'String',
   studentConcessionObsolete: 'Number',
   studentEnrolledWithinDays: 'Number',
   studentPostcode: 'String',
   validFrom: 'Date',
   validFromOffset: 'Number',
   validTo: 'Date',
   validToOffset: 'Number',
   minEnrolmentsForAnyCourses: 'Number',
   corporatePassDiscount: 'CorporatePassDiscount',
   cosAccount: 'Account',
   courseMustEnrol: 'Course',
   discountConcessionTypes: 'DiscountConcessionType',
   discountCourseClasses: 'DiscountCourseClass',
   discountMemberships: 'DiscountMembership',
   invoiceLineDiscounts: 'InvoiceLineDiscount',
   entityRelationTypes: 'EntityRelationType',
};

export const DiscountConcessionType = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   concessionType: 'ConcessionType',
   discount: 'Discount',
};

export const DiscountCourseClass = {
   createdOn: 'Date',
   discountDollar: 'Number',
   id: 'Number',
   modifiedOn: 'Date',
   predictedStudentsPercentage: 'Number',
   classCost: 'ClassCost',
   courseClass: 'CourseClass',
   discount: 'Discount',
};

export const DiscountMembership = {
   applyToMemberOnly: 'Boolean',
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   discount: 'Discount',
   discountMembershipRelationTypes: 'DiscountMembershipRelationType',
   membershipProduct: 'Product',
};

export const DiscountMembershipRelationType = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   contactRelationType: 'ContactRelationType',
   discountMembership: 'DiscountMembership',
};

export const Document = {
   added: 'Date',
   createdOn: 'Date',
   description: 'String',
   fileUUID: 'String',
   id: 'Number',
   isRemoved: 'Boolean',
   isShared: 'Boolean',
   modifiedOn: 'Date',
   name: 'String',
   webVisibility: 'AttachmentInfoVisibility',
   attachmentRelations: 'AttachmentRelation',
   taggingRelations: 'TagRelation',
   versions: 'DocumentVersion',
   tags: 'Tag',
};

export const DocumentVersion = {
   byteSize: 'Number',
   createdOn: 'Date',
   description: 'String',
   fileName: 'String',
   hash: 'String',
   id: 'Number',
   mimeType: 'String',
   modifiedOn: 'Date',
   pixelHeight: 'Number',
   pixelWidth: 'Number',
   thumbnail: 'String',
   timestamp: 'Date',
   versionId: 'String',
   current: 'Boolean',
   createdByUser: 'SystemUser',
   createdByTutor: 'Tutor',
   document: 'Document',
};

export const EmailTemplate = {
   bodyHtml: 'String',
   bodyPlain: 'String',
   createdOn: 'Date',
   description: 'String',
   automationStatus: 'AutomationStatus',
   entity: 'String',
   id: 'Number',
   keyCode: 'String',
   modifiedOn: 'Date',
   name: 'String',
   subject: 'String',
   type: 'MessageType',
   shortDescription: 'String',
   automationTags: 'String',
   category: 'String',
   automationBindings: 'AutomationBinding',
};

export const Enrolment = {
   associatedCourseIdentifier: 'String',
   attendanceType: 'CourseClassAttendanceType',
   confirmationStatus: 'ConfirmationStatus',
   createdOn: 'Date',
   creditFOEId: 'String',
   creditLevel: 'CreditLevel',
   creditOfferedValue: 'String',
   creditProvider: 'String',
   creditProviderType: 'CreditProviderType',
   creditTotal: 'RecognitionOfPriorLearningIndicator',
   creditType: 'CreditType',
   creditUsedValue: 'String',
   cricosConfirmation: 'String',
   eligibilityExemptionIndicator: 'Boolean',
   feeHelpAmount: 'Number',
   feeHelpStatus: 'EnrolmentVETFeeHelpStatus',
   feeStatus: 'StudentStatusForUnitOfStudy',
   fundingSource: 'ClassFundingSource',
   id: 'Number',
   modifiedOn: 'Date',
   outcomeIdTrainingOrg: 'String',
   studentLoanStatus: 'EnrolmentReportingStatus',
   source: 'PaymentSource',
   status: 'EnrolmentStatus',
   studentIndustryANZSICCode: 'Number',
   studyReason: 'StudyReason',
   suppressAvetmissExport: 'Boolean',
   trainingPlanDeveloped: 'Boolean',
   vetClientID: 'String',
   vetFeeExemptionType: 'VETFeeExemptionType',
   vetFeeIndicator: 'Boolean',
   vetFundingSourceStateID: 'String',
   vetInSchools: 'Boolean',
   vetIsFullTime: 'Boolean',
   vetPurchasingContractID: 'String',
   vetTrainingContractID: 'String',
   vetPurchasingContractScheduleID: 'String',
   assessmentSubmissions: 'AssessmentSubmission',
   attachmentRelations: 'AttachmentRelation',
   courseClass: 'CourseClass',
   customFields: 'CustomField',
   invoiceLines: 'AbstractInvoiceLine',
   quoteLines: 'AbstractInvoiceLine',
   noteRelations: 'NoteRelation',
   outcomes: 'Outcome',
   relatedFundingSource: 'FundingSource',
   student: 'Student',
   surveys: 'Survey',
   taggingRelations: 'TagRelation',
   invoices: 'Invoice',
   class: 'CourseClass',
   tags: 'Tag',
   isClassCompleted: 'Boolean',
};

export const EntityRelation = {
   createdOn: 'Date',
   fromEntityAngelId: 'Number',
   fromEntityIdentifier: 'String',
   id: 'Number',
   modifiedOn: 'Date',
   toEntityAngelId: 'Number',
   toEntityIdentifier: 'String',
   relationType: 'EntityRelationType',
};

export const EntityRelationType = {
   considerHistory: 'Boolean',
   createdOn: 'Date',
   description: 'String',
   fromName: 'String',
   id: 'Number',
   isShownOnWeb: 'Boolean',
   modifiedOn: 'Date',
   name: 'String',
   shoppingCart: 'EntityRelationCartAction',
   toName: 'String',
   entityRelations: 'EntityRelation',
   entityRelationTypeDiscount: 'Discount',
};

export const ExportTemplate = {
   createdOn: 'Date',
   description: 'String',
   automationStatus: 'AutomationStatus',
   entity: 'String',
   id: 'Number',
   keyCode: 'String',
   modifiedOn: 'Date',
   name: 'String',
   outputType: 'OutputType',
   script: 'String',
   preview: 'String',
   shortDescription: 'String',
   automationTags: 'String',
   category: 'String',
   automationBindings: 'AutomationBinding',
};

export const Field = {
   createdOn: 'Date',
   defaultValue: 'String',
   description: 'String',
   id: 'Number',
   mandatory: 'Boolean',
   modifiedOn: 'Date',
   name: 'String',
   order: 'Number',
   property: 'String',
   relatedFieldValue: 'String',
   fieldConfiguration: 'FieldConfiguration',
   fieldHeading: 'FieldHeading',
   dependentFields: 'Field',
   relatedField: 'Field',
};

export const FieldConfiguration = {
   createdOn: 'Date',
   id: 'Number',
   intType: 'Number',
   modifiedOn: 'Date',
   name: 'String',
   createdBy: 'SystemUser',
   fieldConfigurationLink: 'FieldConfigurationLink',
   fieldHeadings: 'FieldHeading',
   fields: 'Field',
};

export const FieldConfigurationLink = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   fieldConfiguration: 'FieldConfiguration',
   fieldConfigurationScheme: 'FieldConfigurationScheme',
};

export const FieldConfigurationScheme = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   name: 'String',
   courses: 'Course',
   products: 'Product',
   createdBy: 'SystemUser',
   fieldConfigurationLinks: 'FieldConfigurationLink',
};

export const FieldHeading = {
   createdOn: 'Date',
   description: 'String',
   fieldOrder: 'Number',
   id: 'Number',
   modifiedOn: 'Date',
   name: 'String',
   fieldConfiguration: 'FieldConfiguration',
   fields: 'Field',
};

export const FundingSource = {
   active: 'Boolean',
   createdOn: 'Date',
   flavour: 'ExportJurisdiction',
   id: 'Number',
   modifiedOn: 'Date',
   courseClasses: 'CourseClass',
   enrolments: 'Enrolment',
   fundingProvider: 'Contact',
   name: 'String',
};

export const FundingUpload = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   outcomeCount: 'Number',
   settings: 'String',
   status: 'FundingStatus',
   fundingUploadOutcomes: 'FundingUploadOutcome',
   systemUser: 'SystemUser',
};

export const FundingUploadOutcome = {
   createdOn: 'Date',
   endDate: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   startDate: 'Date',
   fundingUpload: 'FundingUpload',
   outcome: 'Outcome',
};

export const GradingItem = {
   id: 'Number',
   createdOn: 'Date',
   modifiedOn: 'Date',
   itemName: 'String',
   lowerBound: 'Number',
   gradingType: 'GradingType',
};

export const GradingType = {
   id: 'Number',
   createdOn: 'Date',
   modifiedOn: 'Date',
   typeName: 'String',
   minValue: 'Number',
   maxValue: 'Number',
   entryType: 'GradingEntryType',
   assessments: 'Assessment',
   gradingItems: 'GradingItem',
};

export const Import = {
   createdOn: 'Date',
   description: 'String',
   automationStatus: 'AutomationStatus',
   entity: 'String',
   id: 'Number',
   keyCode: 'String',
   modifiedOn: 'Date',
   name: 'String',
   script: 'String',
   shortDescription: 'String',
   automationTags: 'String',
   category: 'String',
   automationBindings: 'AutomationBinding',
};

export const IntegrationConfiguration = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   name: 'String',
   type: 'Number',
   integrationProperties: 'IntegrationProperty',
};

export const IntegrationProperty = {
   createdOn: 'Date',
   id: 'Number',
   keyCode: 'String',
   modifiedOn: 'Date',
   value: 'String',
   integrationConfiguration: 'IntegrationConfiguration',
};

export const Invoice = {
   tags: 'Tag',
   allowAutoPay: 'Boolean',
   amountOwing: 'Number',
   billToAddress: 'String',
   confirmationStatus: 'ConfirmationStatus',
   createdOn: 'Date',
   customerReference: 'String',
   dateDue: 'Date',
   description: 'String',
   id: 'Number',
   invoiceDate: 'Date',
   invoiceNumber: 'Number',
   quoteNumber: 'Number',
   modifiedOn: 'Date',
   overdue: 'Number',
   publicNotes: 'String',
   shippingAddress: 'String',
   source: 'PaymentSource',
   type: 'InvoiceType',
   title: 'String',
   paymentInLines: 'PaymentInLine',
   paymentOutLines: 'PaymentOutLine',
   invoiceLines: 'AbstractInvoiceLine',
   noteRelations: 'NoteRelation',
   attachmentRelations: 'AttachmentRelation',
   authorisedRebillingCard: 'PaymentIn',
   corporatePassUsed: 'CorporatePass',
   createdByUser: 'SystemUser',
   debtorsAccount: 'Account',
   invoiceDueDates: 'InvoiceDueDate',
   lead: 'Lead',
   taggingRelations: 'TagRelation',
   abstractInvoiceLines: 'AbstractInvoiceLine',
   contact: 'Contact',
   accountTransactions: 'AccountTransaction',
   courseClasses: 'CourseClass',
   banking: 'Banking',
   paymentsOut: 'PaymentOut',
   paymentsIn: 'PaymentIn',
   classes: 'CourseClass',
   discounts: 'Discount',
   enrolments: 'Enrolment',
};

export const InvoiceDueDate = {
   amount: 'Number',
   createdOn: 'Date',
   dueDate: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   invoice: 'AbstractInvoice',
};

export const InvoiceLineDiscount = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   discount: 'Discount',
   invoiceLine: 'AbstractInvoiceLine',
   quoteLine: 'AbstractInvoiceLine',
};

export const Language = {
   absCode: 'String',
   createdOn: 'Date',
   id: 'Number',
   isActive: 'Boolean',
   modifiedOn: 'Date',
   name: 'String',
   students: 'Student',
};

export const Lead = {
   id: 'Number',
   createdOn: 'Date',
   modifiedOn: 'Date',
   studentCount: 'Number',
   studentNotes: 'String',
   estimatedValue: 'Number',
   nextActionOn: 'Date',
   status: 'LeadStatus',
   invoices: 'AbstractInvoice',
   quotes: 'AbstractInvoice',
   customer: 'Contact',
   attachmentRelations: 'AttachmentRelation',
   customFields: 'CustomField',
   items: 'LeadItem',
   noteRelations: 'NoteRelation',
   leadSites: 'LeadSite',
   taggingRelations: 'TagRelation',
   assignedTo: 'SystemUser',
   tags: 'Tag',
};

export const LeadItem = {
   id: 'Number',
   createdOn: 'Date',
   modifiedOn: 'Date',
   course: 'Course',
   lead: 'Lead',
   product: 'Product',
};

export const LeadSite = {
   id: 'Number',
   createdOn: 'Date',
   modifiedOn: 'Date',
   lead: 'Lead',
   site: 'Site',
};

export const Membership = {
   confirmationStatus: 'ConfirmationStatus',
   createdOn: 'Date',
   expiryDate: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   status: 'ProductStatus',
   code: 'String',
   valueOnPurchase: 'Number',
   redemptionValue: 'Number',
   noteRelations: 'NoteRelation',
   taggingRelations: 'TagRelation',
   invoiceLine: 'AbstractInvoiceLine',
   product: 'Product',
   redeemedInvoice: 'Invoice',
   redeemedPaymentIn: 'PaymentIn',
   redeemableBy: 'Contact',
   purchasedBy: 'Contact',
   redeemedEnrolment: 'Enrolment',
   type: 'ProductType',
};

export const MembershipProduct = {
   createdOn: 'Date',
   description: 'String',
   expiryDays: 'Number',
   expiryType: 'ExpiryType',
   id: 'Number',
   isOnSale: 'Boolean',
   isWebVisible: 'Boolean',
   modifiedOn: 'Date',
   name: 'String',
   notes: 'String',
   priceExTax: 'Number',
   sku: 'String',
   taxAdjustment: 'Number',
   checkoutRelations: 'CheckoutContactRelation',
   corporatePassProducts: 'CorporatePassProduct',
   incomeAccount: 'Account',
   fieldConfigurationScheme: 'FieldConfigurationScheme',
   leadItems: 'LeadItem',
   productItems: 'ProductItem',
   tax: 'Tax',
   type: 'ProductType',
   allRelatedCourses: 'Course',
   allRelatedProducts: 'Product',
};

export const Message = {
   createdOn: 'Date',
   creatorKey: 'String',
   emailBody: 'String',
   emailFrom: 'String',
   emailHtmlBody: 'String',
   emailSubject: 'String',
   id: 'Number',
   modifiedOn: 'Date',
   postDescription: 'String',
   smsText: 'String',
   response: 'String',
   timeOfDelivery: 'Date',
   status: 'MessageStatus',
   type: 'MessageType',
   numberOfAttempts: 'Number',
   destinationAddress: 'String',
   contact: 'Contact',
   createdBy: 'SystemUser',
   tags: 'Tag',
};

export const Module = {
   createdOn: 'Date',
   creditPoints: 'Number',
   isCreditPointsShownOnWeb: 'Boolean',
   isCreditPointsOffered: 'Boolean',
   expiryDays: 'Number',
   fieldOfEducation: 'String',
   id: 'Number',
   isCustom: 'Boolean',
   isOffered: 'Boolean',
   modifiedOn: 'Date',
   nationalCode: 'String',
   nominalHours: 'Number',
   specialization: 'String',
   title: 'String',
   type: 'ModuleType',
   assessmentClassModules: 'AssessmentClassModule',
   defaultQualification: 'Qualification',
   outcomes: 'Outcome',
   sessionModules: 'SessionModule',
   trainingPackage: 'TrainingPackage',
   courses: 'Course',
   invoices: 'Invoice',
};

export const Note = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   interactionDate: 'Date',
   note: 'String',
   changedBy: 'SystemUser',
   createdByTutor: 'Tutor',
   noteRelations: 'NoteRelation',
   systemUser: 'SystemUser',
};

export const NoteRelation = {
   createdOn: 'Date',
   entityIdentifier: 'String',
   entityRecordId: 'Number',
   id: 'Number',
   modifiedOn: 'Date',
   note: 'Note',
};

export const Outcome = {
   createdOn: 'Date',
   deliveryMode: 'DeliveryMode',
   endDate: 'Date',
   endDateOverridden: 'Boolean',
   fundingSource: 'ClassFundingSource',
   hoursAttended: 'Number',
   id: 'Number',
   markedByTutorDate: 'Date',
   modifiedOn: 'Date',
   reportableHours: 'Number',
   specificProgramIdentifier: 'String',
   startDate: 'Date',
   startDateOverridden: 'Boolean',
   status: 'OutcomeStatus',
   vetFundingSourceStateID: 'String',
   vetPurchasingContractID: 'String',
   vetPurchasingContractScheduleID: 'String',
   certificateOutcomes: 'CertificateOutcome',
   enrolment: 'Enrolment',
   fundingUploadOutcomes: 'FundingUploadOutcome',
   markedByTutor: 'Tutor',
   module: 'Module',
   priorLearning: 'PriorLearning',
   vet: 'Boolean',
   contact: 'Contact',
};

export const PayLine = {
   budgetedQuantity: 'Number',
   budgetedTaxValue: 'Number',
   budgetedValue: 'Number',
   createdOn: 'Date',
   dateFor: 'Date',
   description: 'String',
   id: 'Number',
   modifiedOn: 'Date',
   quantity: 'Number',
   taxValue: 'Number',
   value: 'Number',
   classCost: 'ClassCost',
   payslip: 'Payslip',
   session: 'Session',
};

export const PayRate = {
   createdOn: 'Date',
   description: 'String',
   id: 'Number',
   modifiedOn: 'Date',
   oncostRate: 'Number',
   rate: 'Number',
   type: 'ClassCostRepetitionType',
   validFrom: 'Date',
   definedTutorRole: 'DefinedTutorRole',
   editedByUser: 'SystemUser',
};

export const PaymentIn = {
   amount: 'Number',
   billingId: 'String',
   chequeBank: 'String',
   chequeBranch: 'String',
   chequeDrawer: 'String',
   confirmationStatus: 'ConfirmationStatus',
   createdOn: 'Date',
   creditCardExpiry: 'String',
   creditCardName: 'String',
   creditCardNumber: 'String',
   creditCardType: 'CreditCardType',
   gatewayReference: 'String',
   gatewayResponse: 'String',
   id: 'Number',
   modifiedOn: 'Date',
   paymentDate: 'Date',
   privateNotes: 'String',
   reconciled: 'Boolean',
   sessionId: 'String',
   source: 'PaymentSource',
   status: 'PaymentStatus',
   accountIn: 'Account',
   administrationCentre: 'Site',
   authorisedInvoices: 'AbstractInvoice',
   banking: 'Banking',
   createdBy: 'SystemUser',
   payer: 'Contact',
   paymentInLines: 'PaymentInLine',
   paymentMethod: 'PaymentMethod',
   reversalOf: 'PaymentIn',
   reversedBy: 'PaymentIn',
   undepositedFundsAccount: 'Account',
   voucherPayments: 'VoucherPaymentIn',
   accountTransactions: 'AccountTransaction',
   invoices: 'Invoice',
};

export const PaymentInLine = {
   amount: 'Number',
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   accountOut: 'Account',
   invoice: 'AbstractInvoice',
   paymentIn: 'PaymentIn',
   payslip: 'Payslip',
};

export const PaymentMethod = {
   active: 'Boolean',
   bankedAutomatically: 'Boolean',
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   name: 'String',
   reconcilable: 'Boolean',
   type: 'PaymentType',
   account: 'Account',
   createdBy: 'SystemUser',
   paymentIns: 'PaymentIn',
   paymentOuts: 'PaymentOut',
   undepositedFundsAccount: 'Account',
};

export const PaymentOut = {
   amount: 'Number',
   chequeBank: 'String',
   chequeBranch: 'String',
   chequeDrawer: 'String',
   chequeNumber: 'String',
   confirmationStatus: 'ConfirmationStatus',
   createdOn: 'Date',
   creditCardExpiry: 'String',
   creditCardName: 'String',
   creditCardNumber: 'String',
   creditCardType: 'CreditCardType',
   gatewayReference: 'String',
   id: 'Number',
   modifiedOn: 'Date',
   paymentDate: 'Date',
   paymentInGatewayReference: 'String',
   privateNotes: 'String',
   reconciled: 'Boolean',
   status: 'PaymentStatus',
   accountOut: 'Account',
   administrationCentre: 'Site',
   banking: 'Banking',
   createdBy: 'SystemUser',
   payee: 'Contact',
   paymentMethod: 'PaymentMethod',
   paymentOutLines: 'PaymentOutLine',
   undepositedFundsAccount: 'Account',
   accountTransactions: 'AccountTransaction',
   invoices: 'Invoice',
};

export const PaymentOutLine = {
   amount: 'Number',
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   accountIn: 'Account',
   invoice: 'AbstractInvoice',
   paymentOut: 'PaymentOut',
};

export const Payslip = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   notes: 'String',
   payType: 'PayslipPayType',
   privateNotes: 'String',
   status: 'PayslipStatus',
   contact: 'Contact',
   paylines: 'PayLine',
   taggingRelations: 'TagRelation',
   tags: 'Tag',
};

export const Postcode = {
   description: 'String',
   latitude: 'Number',
   longitude: 'Number',
   postcode: 'String',
   state: 'String',
   suburb: 'String',
   type: 'PostcodeType',
};

export const Preference = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   name: 'String',
   uniqueKey: 'String',
   value: 'String',
   valueString: 'String',
   user: 'SystemUser',
};

export const PriorLearning = {
   createdOn: 'Date',
   externalRef: 'String',
   id: 'Number',
   modifiedOn: 'Date',
   notes: 'String',
   outcomeIdTrainingOrg: 'String',
   title: 'String',
   attachmentRelations: 'AttachmentRelation',
   outcomes: 'Outcome',
   qualification: 'Qualification',
   student: 'Student',
};

export const Product = {
   createdOn: 'Date',
   description: 'RichText',
   expiryDays: 'Number',
   expiryType: 'ExpiryType',
   id: 'Number',
   isOnSale: 'Boolean',
   isWebVisible: 'Boolean',
   modifiedOn: 'Date',
   name: 'String',
   notes: 'String',
   priceExTax: 'Number',
   sku: 'String',
   taxAdjustment: 'Number',
   checkoutRelations: 'CheckoutContactRelation',
   corporatePassProducts: 'CorporatePassProduct',
   incomeAccount: 'Account',
   fieldConfigurationScheme: 'FieldConfigurationScheme',
   leadItems: 'LeadItem',
   productItems: 'ProductItem',
   tax: 'Tax',
   type: 'ProductType',
   allRelatedCourses: 'Course',
   allRelatedProducts: 'Product',
};

export const ProductItem = {
   confirmationStatus: 'ConfirmationStatus',
   createdOn: 'Date',
   expiryDate: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   status: 'ProductStatus',
   code: 'String',
   valueOnPurchase: 'Number',
   redemptionValue: 'Number',
   noteRelations: 'NoteRelation',
   taggingRelations: 'TagRelation',
   invoiceLine: 'AbstractInvoiceLine',
   product: 'Product',
   redeemedInvoice: 'Invoice',
   redeemedPaymentIn: 'PaymentIn',
   redeemableBy: 'Contact',
   purchasedBy: 'Contact',
   redeemedEnrolment: 'Enrolment',
   type: 'ProductType',
};

export const PortalWebsite = {
   id: 'Number',
   createdOn: 'Date',
   modifiedOn: 'Date',
   subDomain: 'String',
};

export const Qualification = {
   anzsco: 'String',
   createdOn: 'Date',
   fieldOfEducation: 'String',
   id: 'Number',
   isCustom: 'Boolean',
   isOffered: 'Boolean',
   level: 'String',
   modifiedOn: 'Date',
   nationalCode: 'String',
   newApprenticeship: 'String',
   nominalHours: 'Number',
   reviewDate: 'Date',
   specialization: 'String',
   title: 'String',
   type: 'QualificationType',
   certificates: 'Certificate',
   courses: 'Course',
   defaultModules: 'Module',
   priorLearnings: 'PriorLearning',
   trainingPackage: 'TrainingPackage',
};

export const QualityRule = {
   active: 'Boolean',
   createdOn: 'Date',
   entity: 'String',
   groupName: 'String',
   id: 'Number',
   keyCode: 'String',
   modifiedOn: 'Date',
   name: 'String',
   script: 'String',
};

export const Report = {
   createdOn: 'Date',
   data: 'String',
   description: 'String',
   automationStatus: 'AutomationStatus',
   entity: 'String',
   id: 'Number',
   keyCode: 'String',
   modifiedOn: 'Date',
   name: 'String',
   preview: 'String',
   sortOn: 'String',
   shortDescription: 'String',
   automationTags: 'String',
   category: 'String',
   background: 'ReportOverlay',
   taggingRelations: 'TagRelation',
   automationBindings: 'AutomationBinding',
   tags: 'Tag',
};

export const ReportOverlay = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   name: 'String',
   overlay: 'String',
};

export const Room = {
   createdOn: 'Date',
   directions: 'RichText',
   facilities: 'RichText',
   id: 'Number',
   modifiedOn: 'Date',
   name: 'String',
   seatedCapacity: 'Number',
   customFields: 'CustomField',
   attachmentRelations: 'AttachmentRelation',
   courseClasses: 'CourseClass',
   noteRelations: 'NoteRelation',
   sessions: 'Session',
   site: 'Site',
   taggingRelations: 'TagRelation',
   unavailableRuleRelations: 'UnavailableRuleRelation',
   tags: 'Tag',
   classes: 'CourseClass',
};

export const SavedFind = {
   aqlExpressionString: 'String',
   createdOn: 'Date',
   expressionString: 'String',
   id: 'Number',
   modifiedOn: 'Date',
   name: 'String',
   tableName: 'String',
   systemUser: 'SystemUser',
};

export const Script = {
   createdOn: 'Date',
   cronSchedule: 'String',
   description: 'String',
   automationStatus: 'AutomationStatus',
   entityClass: 'String',
   entityAttribute: 'String',
   entityEventType: 'EntityEvent',
   id: 'Number',
   keyCode: 'String',
   modifiedOn: 'Date',
   name: 'String',
   outputType: 'OutputType',
   script: 'String',
   systemEventType: 'SystemEventType',
   triggerType: 'TriggerType',
   shortDescription: 'String',
   automationTags: 'String',
   category: 'String',
   automationBindings: 'AutomationBinding',
};

export const Session = {
   createdOn: 'Date',
   endDatetime: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   privateNotes: 'String',
   publicNotes: 'String',
   startDatetime: 'Date',
   attachmentRelations: 'AttachmentRelation',
   attendance: 'Attendance',
   courseClass: 'CourseClass',
   payLines: 'PayLine',
   room: 'Room',
   sessionModules: 'SessionModule',
   sessionTutors: 'TutorAttendance',
   tutor: 'Tutor',
   class: 'CourseClass',
};

export const SessionModule = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   module: 'Module',
   session: 'Session',
};

export const Site = {
   createdOn: 'Date',
   drivingDirections: 'RichText',
   id: 'Number',
   isAdministrationCentre: 'Boolean',
   isShownOnWeb: 'Boolean',
   isVirtual: 'Boolean',
   latitude: 'Number',
   localTimezone: 'String',
   longitude: 'Number',
   modifiedOn: 'Date',
   name: 'String',
   postcode: 'String',
   publicTransportDirections: 'RichText',
   specialInstructions: 'RichText',
   state: 'String',
   street: 'String',
   suburb: 'String',
   customFields: 'CustomField',
   attachmentRelations: 'AttachmentRelation',
   bankings: 'Banking',
   country: 'Country',
   siteLeads: 'LeadSite',
   noteRelations: 'NoteRelation',
   paymentsIn: 'PaymentIn',
   paymentsOut: 'PaymentOut',
   rooms: 'Room',
   taggingRelations: 'TagRelation',
   unavailableRuleRelations: 'UnavailableRuleRelation',
   users: 'SystemUser',
   waitingListSite: 'WaitingListSite',
   tags: 'Tag',
};

export const Student = {
   chessn: 'String',
   citizenship: 'StudentCitizenship',
   clientIndustryEmployment: 'ClientIndustryEmploymentType',
   clientOccupationIdentifier: 'ClientOccupationIdentifierType',
   concessionNumberObsolete: 'String',
   concessionTypeObsolete: 'Number',
   createdOn: 'Date',
   disabilityType: 'AvetmissStudentDisabilityType',
   englishProficiency: 'AvetmissStudentEnglishProficiency',
   feeHelpEligible: 'Boolean',
   highestSchoolLevel: 'AvetmissStudentSchoolLevel',
   id: 'Number',
   indigenousStatus: 'AvetmissStudentIndigenousStatus',
   isOverseasClient: 'Boolean',
   isStillAtSchool: 'Boolean',
   labourForceStatus: 'AvetmissStudentLabourStatus',
   medicalInsurance: 'String',
   modifiedOn: 'Date',
   passportNumber: 'String',
   priorEducationCode: 'AvetmissStudentPriorEducation',
   specialNeeds: 'String',
   specialNeedsAssistance: 'Boolean',
   studentNumber: 'Number',
   townOfBirth: 'String',
   uniqueLearnerIndentifier: 'String',
   usi: 'String',
   usiStatus: 'UsiStatus',
   visaExpiryDate: 'Date',
   visaNumber: 'String',
   visaType: 'String',
   yearSchoolCompleted: 'Number',
   customFields: 'CustomField',
   applications: 'Application',
   attachmentRelations: 'AttachmentRelation',
   attendances: 'Attendance',
   certificates: 'Certificate',
   concessions: 'StudentConcession',
   contact: 'Contact',
   countryOfBirth: 'Country',
   countryOfResidency: 'Country',
   enrolments: 'Enrolment',
   language: 'Language',
   priorLearnings: 'PriorLearning',
   waitingLists: 'WaitingList',
   outcomes: 'Outcome',
   tags: 'Tag',
};

export const StudentConcession = {
   authorisationExpiresOn: 'Date',
   authorisedOn: 'Date',
   concessionNumber: 'String',
   createdOn: 'Date',
   expiresOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   authorisedBy: 'Contact',
   concessionType: 'ConcessionType',
   student: 'Student',
};

export const Survey = {
   comment: 'String',
   courseScore: 'Number',
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   netPromoterScore: 'Number',
   testimonial: 'String',
   tutorScore: 'Number',
   venueScore: 'Number',
   visibility: 'SurveyVisibility',
   customFields: 'CustomField',
   enrolment: 'Enrolment',
   fieldConfiguration: 'FieldConfiguration',
};

export const SystemUser = {
   canEditCMS: 'Boolean',
   canEditTara: 'Boolean',
   createdOn: 'Date',
   email: 'String',
   firstName: 'String',
   id: 'Number',
   isActive: 'Boolean',
   isAdmin: 'Boolean',
   lastAccess: 'Date',
   lastLoginIP: 'String',
   lastLoginOn: 'Date',
   lastName: 'String',
   login: 'String',
   modifiedOn: 'Date',
   password: 'String',
   passwordLastChanged: 'Date',
   passwordUpdateRequired: 'Boolean',
   sessionId: 'String',
   token: 'String',
   tokenScratchCodes: 'String',
   loginAttemptNumber: 'Number',
   invitationToken: 'String',
   invitationTokenExpiryDate: 'Date',
   apiTokens: 'ApiToken',
   approvedTutorAttendances: 'TutorAttendance',
   audits: 'Audit',
   changedNotes: 'Note',
   classCosts: 'ClassCost',
   contactDuplicates: 'ContactDuplicate',
   createdApplications: 'Application',
   createdBankings: 'Banking',
   createdMessages: 'Message',
   defaultAdministrationCentre: 'Site',
   documentVersions: 'DocumentVersion',
   fundingUploads: 'FundingUpload',
   invoicesCreated: 'AbstractInvoice',
   notes: 'Note',
   payRates: 'PayRate',
   paymentsIn: 'PaymentIn',
   paymentsOut: 'PaymentOut',
   preferences: 'Preference',
   savedFinds: 'SavedFind',
   tutorAttendance: 'TutorAttendance',
};

export const ApiToken = {
   id: 'Number',
   modifiedOn: 'Date',
   createdOn: 'Date',
   name: 'String',
   secret: 'String',
   lastAccess: 'Date',
   systemUser: 'SystemUser',
};

export const Tag = {
   colour: 'String',
   contents: 'RichText',
   createdOn: 'Date',
   id: 'Number',
   isWebVisible: 'Boolean',
   modifiedOn: 'Date',
   name: 'String',
   shortWebDescription: 'String',
   nodeType: 'NodeType',
   shortName: 'String',
   specialType: 'NodeSpecialType',
   weight: 'Number',
   attachmentRelations: 'AttachmentRelation',
   childTags: 'Tag',
   parentTag: 'Tag',
   tagRelations: 'TagRelation',
   tagRequirements: 'TagRequirement',
};

export const TagRelation = {
   createdOn: 'Date',
   entityAngelId: 'Number',
   entityIdentifier: 'Number',
   id: 'Number',
   modifiedOn: 'Date',
   tag: 'Tag',
};

export const TagRequirement = {
   createdOn: 'Date',
   entityIdentifier: 'TaggableClasses',
   id: 'Number',
   isRequired: 'Boolean',
   manyTermsAllowed: 'Boolean',
   modifiedOn: 'Date',
   displayRule: 'String',
   tag: 'Tag',
};

export const Tax = {
   createdOn: 'Date',
   description: 'String',
   id: 'Number',
   isGSTTaxType: 'Boolean',
   modifiedOn: 'Date',
   rate: 'Number',
   taxCode: 'String',
   accounts: 'Account',
   contacts: 'Contact',
   courseClasses: 'CourseClass',
   createdBy: 'SystemUser',
   invoiceLines: 'AbstractInvoiceLine',
   quoteLines: 'AbstractInvoiceLine',
   payableToAccount: 'Account',
   receivableFromAccount: 'Account',
};

export const TrainingPackage = {
   copyrightCategory: 'String',
   copyrightContact: 'String',
   createdOn: 'Date',
   developer: 'String',
   endorsementFrom: 'Date',
   endorsementTo: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   nationalISC: 'String',
   purchaseFrom: 'String',
   title: 'String',
   type: 'String',
   modules: 'Module',
   qualifications: 'Qualification',
};

export const Tutor = {
   createdOn: 'Date',
   dateFinished: 'Date',
   dateStarted: 'Date',
   familyNameLegal: 'String',
   givenNameLegal: 'String',
   id: 'Number',
   modifiedOn: 'Date',
   payType: 'PayslipPayType',
   payrollRef: 'String',
   resume: 'RichText',
   wwChildrenCheckedOn: 'Date',
   wwChildrenExpiry: 'Date',
   wwChildrenRef: 'String',
   wwChildrenStatus: 'WorkingWithChildrenStatus',
   customFields: 'CustomField',
   assessmentClassTutors: 'AssessmentClassTutor',
   attachmentRelations: 'AttachmentRelation',
   contact: 'Contact',
   courseClassRoles: 'CourseClassTutor',
   markedAttendances: 'Attendance',
   markedOutcomes: 'Outcome',
   tags: 'Tag',
};

export const TutorAttendance = {
   attendanceType: 'AttendanceType',
   createdOn: 'Date',
   actualPayableDurationMinutes: 'Number',
   id: 'Number',
   modifiedOn: 'Date',
   note: 'String',
   endDatetime: 'Date',
   startDatetime: 'Date',
   approvedByUser: 'SystemUser',
   courseClassTutor: 'CourseClassTutor',
   markedByUser: 'SystemUser',
   session: 'Session',
   customFields: 'CustomField',
};

export const UnavailableRule = {
   allDay: 'Boolean',
   createdOn: 'Date',
   endDateTime: 'Date',
   explanation: 'String',
   id: 'Number',
   modifiedOn: 'Date',
   recurrenceFrequency: 'SessionRepetitionType',
   recurrenceInterval: 'Number',
   repetitionCount: 'Number',
   startDateTime: 'Date',
   untilDateTime: 'Date',
   createdBy: 'SystemUser',
   ruleRelation: 'UnavailableRuleRelation',
};

export const UnavailableRuleRelation = {
   createdOn: 'Date',
   entityIdentifier: 'String',
   modifiedOn: 'Date',
   rule: 'UnavailableRule',
};

export const Voucher = {
   redeemedCourseCount: 'Number',
   source: 'PaymentSource',
   confirmationStatus: 'ConfirmationStatus',
   createdOn: 'Date',
   expiryDate: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   status: 'ProductStatus',
   code: 'String',
   valueOnPurchase: 'Number',
   redemptionValue: 'Number',
   noteRelations: 'NoteRelation',
   taggingRelations: 'TagRelation',
   invoiceLine: 'AbstractInvoiceLine',
   product: 'Product',
   redeemedInvoice: 'Invoice',
   redeemedPaymentIn: 'PaymentIn',
   redeemableBy: 'Contact',
   purchasedBy: 'Contact',
   redeemedEnrolment: 'Enrolment',
   type: 'ProductType',
};

export const VoucherPaymentIn = {
   createdOn: 'Date',
   enrolmentsCount: 'Number',
   id: 'Number',
   modifiedOn: 'Date',
   status: 'VoucherPaymentStatus',
   invoiceLine: 'AbstractInvoiceLine',
   paymentIn: 'PaymentIn',
   voucher: 'ProductItem',
};

export const VoucherProduct = {
   maxCoursesRedemption: 'Number',
   value: 'Number',
   createdOn: 'Date',
   description: 'RichText',
   expiryDays: 'Number',
   expiryType: 'ExpiryType',
   id: 'Number',
   isOnSale: 'Boolean',
   isWebVisible: 'Boolean',
   modifiedOn: 'Date',
   name: 'String',
   notes: 'String',
   priceExTax: 'Number',
   sku: 'String',
   taxAdjustment: 'Number',
   checkoutRelations: 'CheckoutContactRelation',
   corporatePassProducts: 'CorporatePassProduct',
   incomeAccount: 'Account',
   fieldConfigurationScheme: 'FieldConfigurationScheme',
   leadItems: 'LeadItem',
   productItems: 'ProductItem',
   tax: 'Tax',
   type: 'ProductType',
   allRelatedCourses: 'Course',
   allRelatedProducts: 'Product',
};

export const VoucherProductCourse = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   course: 'Course',
   voucherProduct: 'Product',
};

export const WaitingList = {
   createdOn: 'Date',
   id: 'Number',
   modifiedOn: 'Date',
   notes: 'String',
   studentCount: 'Number',
   studentNotes: 'String',
   course: 'Course',
   customFields: 'CustomField',
   student: 'Student',
   taggingRelations: 'TagRelation',
   waitingListSites: 'WaitingListSite',
   tags: 'Tag',
};

export const WaitingListSite = {
   id: 'Number',
   site: 'Site',
   waitingList: 'WaitingList',
};

export const Faculty = {
   id: 'Number',
   name: 'String',
   code: 'String',
   webDescription: 'String',
   shortWebDescription: 'String',
   isShownOnWeb: 'Boolean',
   createdOn: 'Date',
   modifiedOn: 'Date',
   courses: 'Course',
   attachmentRelations: 'AttachmentRelation',
   noteRelations: 'NoteRelation',
   taggingRelations: 'TagRelation',
   tags: 'Tag',
};

export class Enum {
  constructor(props) { 
   Object.assign(this, props); 
  }
}

 // Copyright ish group pty ltd 2020.
 //
 //   This program is free software: you can redistribute it and/or modify it under the terms of the
 //   GNU Affero General Public License version 3 as published by the Free Software Foundation.
 // 
 //   This program is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY;
 //   without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
 //   See the GNU Affero General Public License for more details.


export const AccountTransactionType = new Enum ({

    DEPRECIATION: undefined,
    INVOICE_LINE: undefined,
    JOURNAL: undefined,
    PAYMENT_IN_LINE: undefined,
    PAYMENT_OUT_LINE: undefined,
    PURCHASE_LINE: undefined,
});

export const AccountType = new Enum ({

    ASSET: undefined,
    COS: undefined,
    EQUITY: undefined,
    EXPENSE: undefined,
    INCOME: undefined,
    LIABILITY: undefined,
});

export const ApplicationStatus = new Enum ({

    ACCEPTED: undefined,
    IN_PROGRESS: undefined,
    NEW: undefined,
    OFFERED: undefined,
    REJECTED: undefined,
    WITHDRAWN: undefined,
});

export const AttachmentSpecialType = new Enum ({

    PROFILE_PICTURE: undefined,
});

export const AttendanceType = new Enum ({

    ATTENDED: undefined,
    DID_NOT_ATTEND_WITHOUT_REASON: undefined,
    DID_NOT_ATTEND_WITH_REASON: undefined,
    PARTIAL: undefined,
    UNMARKED: undefined,
});

export const AutomationStatus = new Enum ({

    ENABLED: undefined,
    INSTALLED_DISABLED: undefined,
    NOT_INSTALLED: undefined,
});

export const AvetmissStudentDisabilityType = new Enum ({

    BRAIN_IMPAIRMENT: undefined,
    DEFAULT_POPUP_OPTION: undefined,
    HEARING: undefined,
    INTELLECTUAL: undefined,
    LEARNING: undefined,
    MEDICAL_CONDITION: undefined,
    MENTAL: undefined,
    NONE: undefined,
    OTHER: undefined,
    PHYSICAL: undefined,
    VISION: undefined,
});

export const AvetmissStudentEnglishProficiency = new Enum ({

    DEFAULT_POPUP_OPTION: undefined,
    NOT_AT_ALL: undefined,
    NOT_WELL: undefined,
    VERY_WELL: undefined,
    WELL: undefined,
});

export const AvetmissStudentIndigenousStatus = new Enum ({

    ABORIGINAL: undefined,
    ABORIGINAL_AND_TORRES: undefined,
    DEFAULT_POPUP_OPTION: undefined,
    NEITHER: undefined,
    TORRES: undefined,
});

export const AvetmissStudentLabourStatus = new Enum ({

    DEFAULT_POPUP_OPTION: undefined,
    EMPLOYER: undefined,
    FULL_TIME: undefined,
    PART_TIME: undefined,
    SELF_EMPLOYED: undefined,
    UNEMPLOYED_NOT_SEEKING: undefined,
    UNEMPLOYED_SEEKING_FULL_TIME: undefined,
    UNEMPLOYED_SEEKING_PART_TIME: undefined,
    UNPAID_FAMILY_WORKER: undefined,
});

export const AvetmissStudentPriorEducation = new Enum ({

    ADVANCED_DIPLOMA: undefined,
    BACHELOR: undefined,
    CERTIFICATE_I: undefined,
    CERTIFICATE_II: undefined,
    CERTIFICATE_III: undefined,
    CERTIFICATE_IV: undefined,
    DEFAULT_POPUP_OPTION: undefined,
    DIPLOMA: undefined,
    MISC: undefined,
    NONE: undefined,
});

export const AvetmissStudentSchoolLevel = new Enum ({

    COMPLETED_YEAR_10: undefined,
    COMPLETED_YEAR_11: undefined,
    COMPLETED_YEAR_12: undefined,
    COMPLETED_YEAR_8_OR_BELOW: undefined,
    COMPLETED_YEAR_9: undefined,
    DEFAULT_POPUP_OPTION: undefined,
    DID_NOT_GO_TO_SCHOOL: undefined,
});

export const BankingType = new Enum ({

    AUTO_AMEX: undefined,
    AUTO_MCVISA: undefined,
    AUTO_OTHER: undefined,
    GATEWAY: undefined,
    MANUAL: undefined,
});

export const ClassCostFlowType = new Enum ({

    DISCOUNT: undefined,
    EXPENSE: undefined,
    INCOME: undefined,
    WAGES: undefined,
});

export const ClassCostRepetitionType = new Enum ({

    DISCOUNT: undefined,
    FIXED: undefined,
    PER_ENROLMENT: undefined,
    PER_SESSION: undefined,
    PER_STUDENT_CONTACT_HOUR: undefined,
    PER_TIMETABLED_HOUR: undefined,
    PER_UNIT: undefined,
});

export const ClassFundingSource = new Enum ({

    COMMONWEALTH_AND_STATE_GENERAL: undefined,
    COMMONWEALTH_SPECIFIC: undefined,
    DOMESTIC_FULL_FEE: undefined,
    INTERNATIONAL_FULL_FEE: undefined,
    INTERNATIONAL_OFFSHORE: undefined,
    INTERNATIONAL_ONSHORE: undefined,
    REVENUE_FROM_OTHER_TO: undefined,
    STATE_SPECIFIC: undefined,
});

export const ClientIndustryEmploymentType = new Enum ({

    ACCOMODATION: undefined,
    ADMIN: undefined,
    AGRICULTURE: undefined,
    ARTS: undefined,
    CONSTRUCTION: undefined,
    EDUCATION: undefined,
    ELECTRICITY: undefined,
    FINANCIAL: undefined,
    HEALTH: undefined,
    MANUFACTURING: undefined,
    MEDIA: undefined,
    MINING: undefined,
    NOT_SET: undefined,
    OTHER: undefined,
    PROFESSIONAL: undefined,
    RENTAL: undefined,
    RETAIL: undefined,
    TRANSPORT: undefined,
    WHOLESALE: undefined,
});

export const ClientOccupationIdentifierType = new Enum ({

    CLERICAL: undefined,
    COMMUNITY: undefined,
    LABOURERS: undefined,
    MACHINERY: undefined,
    MANAGER: undefined,
    NOT_SET: undefined,
    PROFESSIONALS: undefined,
    SALES: undefined,
    TECHNICIANS: undefined,
});

export const ConfirmationStatus = new Enum ({

    DO_NOT_SEND: undefined,
    NOT_SENT: undefined,
    SENT: undefined,
});

export const ContactDuplicateStatus = new Enum ({

    IN_TRANSACTION: undefined,
    POSSIBLE_MATCH: undefined,
    PROCESSED: undefined,
    REJECTED_MATCH: undefined,
});

export const CourseClassAttendanceType = new Enum ({

    FULL_TIME_ATTENDANCE: undefined,
    NO_INFORMATION: undefined,
    OUA_AND_NOT_HIGHER_DEGREE_REASEARCH_STUDENT_USE: undefined,
    PART_TIME_ATTENDANCE: undefined,
});

export const CourseClassType = new Enum ({

    DISTANT_LEARNING: undefined,
    HYBRID: undefined,
    WITH_SESSIONS: undefined,
});

export const CourseEnrolmentType = new Enum ({

    ENROLMENT_BY_APPLICATION: undefined,
    OPEN_FOR_ENROLMENT: undefined,
});

export const CreditCardType = new Enum ({

    AMEX: undefined,
    BANKCARD: undefined,
    MASTERCARD: undefined,
    VISA: undefined,
});

export const CreditLevel = new Enum ({

    ADVANCED_DIPLOMA: undefined,
    BRIDGING_AND_ENABLING_COURSE_AT_ADVANCED_DIPLOMA: undefined,
    BRIDGING_AND_ENABLING_COURSE_AT_CERTIFICATE_2_LEVEL: undefined,
    BRIDGING_AND_ENABLING_COURSE_AT_CERTIFICATE_3_LEVEL: undefined,
    BRIDGING_AND_ENABLING_COURSE_AT_CERTIFICATE_4_LEVEL: undefined,
    BRIDGING_AND_ENABLING_COURSE_AT_DIPLOMA: undefined,
    CERTIFICATE_1_LEVEL: undefined,
    CERTIFICATE_2_LEVEL: undefined,
    CERTIFICATE_3_LEVEL: undefined,
    CERTIFICATE_4_LEVEL: undefined,
    DIPLOMA: undefined,
    NO_CREDIT_RPL_WAS_OFFERED_FOR_VET: undefined,
    OTHER: undefined,
    STATEMENT_OF_ATTEINMENT_AT_ADVANCED_DIPLOMA_LEVEL: undefined,
    STATEMENT_OF_ATTEINMENT_AT_CERTIFICATE_1_LEVEL: undefined,
    STATEMENT_OF_ATTEINMENT_AT_CERTIFICATE_2_LEVEL: undefined,
    STATEMENT_OF_ATTEINMENT_AT_CERTIFICATE_3_LEVEL: undefined,
    STATEMENT_OF_ATTEINMENT_AT_CERTIFICATE_4_LEVEL: undefined,
    STATEMENT_OF_ATTEINMENT_AT_DIPLOMA_LEVEL: undefined,
    VOCATIONAL_GRADUATE_CERTIFICATE: undefined,
    VOCATIONAL_GRADUATE_DIPLOMA: undefined,
});

export const CreditProviderType = new Enum ({

    NOT_ELSEWHERE_CATEGORIZED: undefined,
    NO_CREDIT_RPL_WAS_OFFERED_FOR_VET: undefined,
    OTHER_HIGHER_EDUCATION_PROVIDER: undefined,
    OTHER_REGISTERED_TRAINING_ORGANIZATIONS: undefined,
    SECONDARY_SCHOOLS_OR_COLLEGES: undefined,
    TAFE: undefined,
    UNIVERSITY: undefined,
});

export const CreditType = new Enum ({

    CREDIT_RPL_FOR_COMBINATION_OF_PRIOR_HIGHER_EDUCATION_AND_VET_STUDY: undefined,
    CREDIT_RPL_FOR_PRIOR_HIGHER_EDUCATION_STUDY_ONLY: undefined,
    CREDIT_RPL_FOR_PRIOR_VET_STUDY_ONLY: undefined,
    CREDIT_RPL_FOR_STUDY_OUTSIDE_AUSTRALIA: undefined,
    CREDIT_RPL_FOR_WORK_EXPERIENCE: undefined,
    NO_CREDIT_RPL_WAS_OFFERED: undefined,
    OTHER: undefined,
});

export const DataType = new Enum ({

    BOOLEAN: undefined,
    DATE: undefined,
    DATE_TIME: undefined,
    EMAIL: undefined,
    ENTITY: undefined,
    FILE: undefined,
    LIST: undefined,
    LONG_TEXT: undefined,
    MAP: undefined,
    MESSAGE_TEMPLATE: undefined,
    MONEY: undefined,
    NUMBER: undefined,
    OBJECT: undefined,
    PATTERN_TEXT: undefined,
    PORTAL_SUBDOMAIN: undefined,
    TEXT: undefined,
    URL: undefined,
});

export const DeliveryMode = new Enum ({

    CLASSROOM: undefined,
    CLASSROOM_AND_ONLINE: undefined,
    CLASSROOM_AND_WORKSPACE: undefined,
    CLASSROOM_ONLINE_AND_WORKSPACE: undefined,
    NA: undefined,
    NOT_SET: undefined,
    ONLINE: undefined,
    ONLINE_AND_WORKSPACE: undefined,
    OTHER: undefined,
    WA_EXTERNAL: undefined,
    WA_INTERNET_SITE: undefined,
    WA_LOCAL_CLASS: undefined,
    WA_REMOTE_CLASS: undefined,
    WA_SELF_PACED_SCHEDULED: undefined,
    WA_SELF_PACED_UNSCHEDULED: undefined,
    WA_VIDEO_LEARNING: undefined,
    WA_WORKPLACE: undefined,
    WORKPLACE: undefined,
});

export const DiscountAvailabilityType = new Enum ({

    OFFICE_ONLY: undefined,
    ONLINE_AND_OFFICE: undefined,
    ONLINE_ONLY: undefined,
});

export const DiscountType = new Enum ({

    DOLLAR: undefined,
    FEE_OVERRIDE: undefined,
    PERCENT: undefined,
});

export const EnrolmentReportingStatus = new Enum ({

    ELIGIBLE: undefined,
    FINALIZED: undefined,
    NOT_ELIGIBLE: undefined,
    ONGOING: undefined,
});

export const EnrolmentStatus = new Enum ({

    CANCELLED: undefined,
    CORRUPTED: undefined,
    FAILED: undefined,
    FAILED_CARD_DECLINED: undefined,
    FAILED_NO_PLACES: undefined,
    IN_TRANSACTION: undefined,
    NEW: undefined,
    QUEUED: undefined,
    REFUNDED: undefined,
    SUCCESS: undefined,
});

export const EnrolmentVETFeeHelpStatus = new Enum ({

    HELP_NOT_REQUESTED: undefined,
    HELP_REQUESTED: undefined,
    NOT_ELIGIBLE: undefined,
});

export const EntityEvent = new Enum ({

    CREATE: undefined,
    CREATE_OR_UPDATE: undefined,
    REMOVE: undefined,
    UPDATE: undefined,
});

export const ExpiryType = new Enum ({

    DAYS: undefined,
    FIRST_JANUARY: undefined,
    FIRST_JULY: undefined,
    LIFETIME: undefined,
});

export const ExportJurisdiction = new Enum ({

    AQTF: undefined,
    AVETARS: undefined,
    NSW: undefined,
    NTVETPP: undefined,
    OLIV: undefined,
    PLAIN: undefined,
    QLD: undefined,
    RAPT: undefined,
    SA: undefined,
    SMART: undefined,
    TAS: undefined,
    VIC: undefined,
    WA: undefined,
});

export const Gender = new Enum ({

    FEMALE: undefined,
    MALE: undefined,
    OTHER_GENDER: undefined,
});

export const InvoiceType = new Enum ({

    INVOICE: undefined,
    QUOTE: undefined,
});

export const LeadStatus = new Enum ({

    CLOSED: undefined,
    OPEN: undefined,
});

export const MessageStatus = new Enum ({

    FAILED: undefined,
    QUEUED: undefined,
    SENT: undefined,
});

export const MessageType = new Enum ({

    EMAIL: undefined,
    POST: undefined,
    SMS: undefined,
});

export const MoneyRounding = new Enum ({

    ROUNDING_10C: undefined,
    ROUNDING_1D: undefined,
    ROUNDING_50C: undefined,
    ROUNDING_NONE: undefined,
});

export const NodeSpecialType = new Enum ({

    ASSESSMENT_METHOD: undefined,
    CLASS_EXTENDED_TYPES: undefined,
    COURSE_EXTENDED_TYPES: undefined,
    HOME_WEBPAGE: undefined,
    MAILING_LISTS: undefined,
    PAYROLL_WAGE_INTERVALS: undefined,
    SUBJECTS: undefined,
    TERMS: undefined,
});

export const NodeType = new Enum ({

    CHECKLIST: undefined,
    TAG: undefined,
});

export const OutcomeStatus = new Enum ({

    STATUS_ASSESSABLE_CONTINUING_ENROLMENT: undefined,
    STATUS_ASSESSABLE_CREDIT_TRANSFER: undefined,
    STATUS_ASSESSABLE_DET_DID_NOT_START: undefined,
    STATUS_ASSESSABLE_FAIL: undefined,
    STATUS_ASSESSABLE_PASS: undefined,
    STATUS_ASSESSABLE_RCC_GRANTED: undefined,
    STATUS_ASSESSABLE_RCC_NOT_GRANTED: undefined,
    STATUS_ASSESSABLE_RPL_GRANTED: undefined,
    STATUS_ASSESSABLE_RPL_NOT_GRANTED: undefined,
    STATUS_ASSESSABLE_WITHDRAWN: undefined,
    STATUS_ASSESSABLE_WITHDRAWN_INCOMPLETE_DUE_TO_RTO: undefined,
    STATUS_NON_ASSESSABLE_COMPLETED: undefined,
    STATUS_NON_ASSESSABLE_NOT_COMPLETED: undefined,
    STATUS_NOT_SET: undefined,
    STATUS_NO_RESULT_QLD: undefined,
    STATUS_SUPERSEDED_QUALIFICATION_QLD: undefined,
    STATUS_WA_DISCONTINUED: undefined,
    STATUS_WA_NOT_YET_STARTED: undefined,
    STATUS_WA_PARTICIPATING_WITHOUT_EVIDENCE: undefined,
    STATUS_WA_PARTICIPATING_WITH_EVIDENCE: undefined,
    STATUS_WA_PROVISIONALLY_COMPETENT: undefined,
    STATUS_WA_RCC_GRANTED: undefined,
    STATUS_WA_RCC_NOT_GRANTED: undefined,
    SUPERSEDED_SUBJECT: undefined,
});

export const PaymentSource = new Enum ({

    SOURCE_ONCOURSE: undefined,
    SOURCE_WEB: undefined,
});

export const PaymentStatus = new Enum ({

    CARD_DETAILS_REQUIRED: undefined,
    CORRUPTED: undefined,
    FAILED: undefined,
    FAILED_CARD_DECLINED: undefined,
    FAILED_NO_PLACES: undefined,
    IN_TRANSACTION: undefined,
    NEW: undefined,
    QUEUED: undefined,
    SUCCESS: undefined,
});

export const PaymentType = new Enum ({

    BPAY: undefined,
    CASH: undefined,
    CHEQUE: undefined,
    CONTRA: undefined,
    CREDIT_CARD: undefined,
    EFT: undefined,
    EFTPOS: undefined,
    INTERNAL: undefined,
    OTHER: undefined,
    PAYPAL: undefined,
    REVERSE: undefined,
    VOUCHER: undefined,
});

export const PayslipPayType = new Enum ({

    CONTRACTOR: undefined,
    EMPLOYEE: undefined,
});

export const PayslipStatus = new Enum ({

    APPROVED: undefined,
    COMPLETED: undefined,
    FINALISED: undefined,
    HOLLOW: undefined,
});

export const PostcodeType = new Enum ({

    DELIVERY_AREA: undefined,
    LARGE_VOLUME_RECEIVER: undefined,
    POST_OFFICE_BOXES: undefined,
});

export const ProductStatus = new Enum ({

    ACTIVE: undefined,
    CANCELLED: undefined,
    CREDITED: undefined,
    DELIVERED: undefined,
    EXPIRED: undefined,
    NEW: undefined,
    REDEEMED: undefined,
});

export const ProductType = new Enum ({

    ARTICLE: undefined,
    MEMBERSHIP: undefined,
    VOUCHER: undefined,
});

export const QualificationType = new Enum ({

    COURSE_TYPE: undefined,
    HIGHER_TYPE: undefined,
    QUALIFICATION_TYPE: undefined,
    SKILLSET_LOCAL_TYPE: undefined,
    SKILLSET_TYPE: undefined,
});

export const RecognitionOfPriorLearningIndicator = new Enum ({

    NOT_RPL_UNIT_OF_STUDY: undefined,
    UNIT_OF_STUDY_CONSISTS_WHOLLY_OF_RPL: undefined,
    UNIT_OF_STUDY_HAS_A_COMPONENT_OF_RPL: undefined,
});

export const SessionRepetitionType = new Enum ({

    CUSTOM_CHOICE: undefined,
    DAY_CHOICE: undefined,
    HOUR_CHOICE: undefined,
    MONTH_CHOICE: undefined,
    NONE_CHOICE: undefined,
    WEEK_CHOICE: undefined,
    YEAR_CHOICE: undefined,
});

export const StudentCitizenship = new Enum ({

    AUSTRALIAN_CITIZEN: undefined,
    NEW_ZELAND_CITIZEN: undefined,
    NONE_OF_THE_ABOVE_CATEGORIES: undefined,
    NO_INFORMATION: undefined,
    STUDENT_WITH_PACIFIC_ENGAGEMENT_VISA: undefined,
    STUDENT_WITH_PERMANENT_HUMANITARIAN_VISA: undefined,
    STUDENT_WITH_PERMANENT_VISA: undefined,
    STUDENT_WITH_TEMPORARY_ENTRY_PERMIT: undefined,
});

export const StudentStatusForUnitOfStudy = new Enum ({

    DEFERRED_ALL_OR_PART_OF_TUITION_FEE_THROUGH_VET_FEE_HELP_AUSTRALIAN_CAPITAL_TERRITORY_GOVERNMENT_SUBSIDISED: undefined,
    DEFERRED_ALL_OR_PART_OF_TUITION_FEE_THROUGH_VET_FEE_HELP_NEW_SOUTH_WALES_STATE_GOVERNMENT_SUBSIDISED: undefined,
    DEFERRED_ALL_OR_PART_OF_TUITION_FEE_THROUGH_VET_FEE_HELP_NON_STATE_GOVERNMENT_SUBSIDISED: undefined,
    DEFERRED_ALL_OR_PART_OF_TUITION_FEE_THROUGH_VET_FEE_HELP_NORTHERN_TERRITORY_GOVERNMENT_SUBSIDISED: undefined,
    DEFERRED_ALL_OR_PART_OF_TUITION_FEE_THROUGH_VET_FEE_HELP_QUEENSLAND_STATE_GOVERNMENT_SUBSIDISED: undefined,
    DEFERRED_ALL_OR_PART_OF_TUITION_FEE_THROUGH_VET_FEE_HELP_RESTRICTED_ACCESS_ARRANGEMENT: undefined,
    DEFERRED_ALL_OR_PART_OF_TUITION_FEE_THROUGH_VET_FEE_HELP_SOUTH_AUSTRALIAN_STATE_GOVERNMENT_SUBSIDISED: undefined,
    DEFERRED_ALL_OR_PART_OF_TUITION_FEE_THROUGH_VET_FEE_HELP_TASMANIA_STATE_GOVERNMENT_SUBSIDISED: undefined,
    DEFERRED_ALL_OR_PART_OF_TUITION_FEE_THROUGH_VET_FEE_HELP_VICTORIAN_STATE_GOVERNMENT_SUBSIDISED: undefined,
    DEFERRED_ALL_OR_PART_OF_TUITION_FEE_THROUGH_VET_FEE_HELP_WESTERN_AUSTRALIAN_STATE_GOVERNMENT_SUBSIDISED: undefined,
});

export const SystemEventType = new Enum ({

    CHECKLIST_COMPLETED: undefined,
    CHECKLIST_TASK_CHECKED: undefined,
    CLASS_CANCELLED: undefined,
    CLASS_PUBLISHED: undefined,
    ENROLMENT_CANCELLED: undefined,
    ENROLMENT_SUCCESSFUL: undefined,
    PAYSLIP_APPROVED: undefined,
    PAYSLIP_PAID: undefined,
    TAG_ADDED: undefined,
    TAG_REMOVED: undefined,
    USER_LOGGED_IN: undefined,
    USER_LOGGED_OUT: undefined,
    VALIDATE_CHECKOUT: undefined,
});

export const TriggerType = new Enum ({

    CRON: undefined,
    ENTITY_EVENT: undefined,
    ONCOURSE_EVENT: undefined,
    ON_DEMAND: undefined,
});

export const UsiStatus = new Enum ({

    DEFAULT_NOT_SUPPLIED: undefined,
    EXEMPTION: undefined,
    INTERNATIONAL: undefined,
    NON_VERIFIED: undefined,
    VERIFIED: undefined,
});

export const VETFeeExemptionType = new Enum ({

    C: undefined,
    D: undefined,
    E: undefined,
    F: undefined,
    G: undefined,
    H: undefined,
    I: undefined,
    J: undefined,
    L: undefined,
    M: undefined,
    N: undefined,
    NO: undefined,
    O: undefined,
    OS: undefined,
    P: undefined,
    Q: undefined,
    S: undefined,
    UNSET: undefined,
    V: undefined,
    YES: undefined,
    Z: undefined,
});

export const VoucherPaymentStatus = new Enum ({

    APPROVED: undefined,
    BUSY: undefined,
    INCONSISTENT: undefined,
});


